@import "../../abstracts/variables"
@import "../../abstracts/mixins"

.chGuideSearch
	.chGuideSearchBox
		+borderRadius(16px)
		overflow: hidden
		+mediaMin960
			+flex
		.chGuideSearchField
			padding: $spacer2
			background-color: #E4E4E4
			+mediaMin960
				width: 35%
			h2, h3, h4, p
				font-weight: 500
				font-family: $fontPrin
				margin-bottom: $spacer2
				+mediaMin960
					margin-bottom: $spacer3
			fieldset
				label
					+ input, + .inputSearch, + .searchComp
						margin-top: $spacer
			.searchComp
				+flex
				input
					width: calc(100% - 72px)
					-webkit-border-top-right-radius: 0
					-webkit-border-bottom-right-radius: 0
					-moz-border-radius-topright: 0
					-moz-border-radius-bottomright: 0
					border-top-right-radius: 0
					border-bottom-right-radius: 0
					border-right: none
				.searchCompBtns
					+bx
					+flex
					position: relative
					width: 72px
					border: $color-border 1px solid
					background-color: #EAEAEA
					-webkit-border-top-right-radius: 12px
					-webkit-border-bottom-right-radius: 12px
					-moz-border-radius-topright: 12px
					-moz-border-radius-bottomright: 12px
					border-top-right-radius: 12px
					border-bottom-right-radius: 12px
					border-left: none
					overflow: hidden
					button
						width: 36px
						color: #909090
						border: none
						border-radius: 0
						cursor: pointer
						&.search
							i
								+font-size(18px)
						&.close
							i
								+font-size(20px)
					&:after
						+center(vh)
						content: ''
						width: 1px
						height: calc(100% - 10px)
						display: block
						background-color: $color-border
		.chGuideSearchFilter
			padding: $spacer2
			background-color: $gris1
			+mediaMin960
				width: 75%
			h2, h3, h4, p
				font-weight: 500
				font-family: $fontPrin
				margin-bottom: $spacer2
				+mediaMin960
					margin-bottom: $spacer3
			.chGSFcolset
				+mediaMin960
					+flex
					+alignItem(flex-end)
					gap: $spacer2
				fieldset
					+mediaMin960
						flex: 1 1 auto
					label
						+ .select
							margin-top: $spacer
					[class*="textLink"]
						+font-size(16px)
						border: none
						+mediaMin960
							margin-bottom: $spacer
					+ fieldset
						margin-top: $spacer2
						+mediaMin960
							margin-top: 0
	+ .chGuideChannels
		padding-top: $spacer
		+mediaMin960
			padding-top: $spacer2


.chGuideChannels
	h2, h3, h4
		font-family: $fontPrin
		font-weight: 400
		margin-bottom: $spacer2
		+mediaMin960
			margin-bottom: $spacer3
	.chGuideChannelsSlider
		padding: 0 60px
		.chGuideChannelBox
			min-height: 93px
		.sc-EHOje
			margin: 0
			padding: 5px
		[type="prev"], [type="next"]
			+bx
			+font-size(24px)
			width: 40px
			height: 40px
			box-shadow: none
			opacity: 1
			border: $color-text 2px solid
			&:before
				width: auto
				height: auto
				-webkit-transform: translate(-50%,-50%)
				-ms-transform: translate(-50%,-50%)
				transform: translate(-50%,-50%)
				left: 50%
				top: 50%
				border: none
		[type="prev"]
			left: -60px
			&:before
				+claroIcon('\e910')
		[type="next"]
			right: -60px
			&:before
				+claroIcon('\e912')
		.sc-htoDjs
			bottom: -30px
			> div
				> div
					width: 12px
					height: 12px
	.chGuideChannelsPlans
		.chGuideChannelPlanBox
			+bx
			+borderRadius(16px)
			padding: $spacer2
			width: 100%
			border: $color-border 1px solid
			background-color: #fff
			h2, h3, h4
				margin-bottom: #{$spacer3 / 2}
			.chGuideChPlanBoxText
				+bx
				+mediaMin960
					+flex
				p
					+ p
						margin-top: $spacer2
				.chGuideChPBTextInfo
					+mediaMin960
						width: calc(100% - 450px)
						padding-right: $spacer5
				.chGuideChPBTextPrecio
					margin-top: $spacer2
					+mediaMin960
						+flex
						+justifyCont(space-between)
						width: 450px
						margin-top: 0
					.chGuideChPBTextPrecioNumber
						span
							display: block
							font-weight: 500
						p
							+font-size(32px)
							font-weight: 700
							color: $rojo6
					.chGuideChPBTextPrecioBtn
						width: 240px
						[class*="btn"]
							width: 100%
			.chGuideChPlanBoxList
				margin-top: $spacer2
				+mediaMin960
					margin-top: $spacer3
				.chGuideChPBListCards
					+flex
					+flexWrap
					+justifyCont(space-between)
					margin-top: -$spacer
					+mediaMin760
						+justifyCont(flex-start)
					.chGuideChannelBox
						width: calc(50% - 10px)
						padding: $spacer 0
						margin-top: $spacer2
						+mediaMin760
							width: 180px
							margin-right: $spacer2
						.chGuideChannelBoxImg
							width: 60px
						.chGuideChannelBoxText
							width: calc(100% - 60px)
			.chGuideChannelBoxMore
				+flex
				+justifyCont
				margin-top: $spacer2
			+ .chGuideChannelPlanBox
				margin-top: $spacer2
	.chGuideChannelsResults
		+mediaMin640
			+flex
			+flexWrap
			margin-top: -$spacer
		.chGuideChannelBox
			+mediaMin640
				width: 220px
				margin-top: $spacer2
				margin-right: $spacer2
			+ .chGuideChannelBox
				margin-top: $spacer2

.chGuideChannelBox
	+bx
	+borderRadius(12px)
	+flex
	width: 100%
	padding: $spacer2 0
	background-color: #fff
	box-shadow: 0px 2px 8px rgba(187, 187, 187, 0.5)
	.chGuideChannelBoxImg
		+bx
		width: 90px
		padding: 0 $spacer
		img
			width: 100%
			height: auto
			max-height: 50px
			object-fit: contain
	.chGuideChannelBoxText
		+bx
		+font-size(14px)
		text-align: left
		width: calc(100% - 90px)
		padding-right: $spacer
		p
			font-weight: 500
			color: $color-text
		span
			color: $gris5
			display: block

.modalChannels
	h3, h4
		font-family: $fontPrin
		margin-bottom: $spacer2
	.modalChannelsFilter
		+flex
		+alignItem
		margin-bottom: $spacer3
		label
			white-space: nowrap
			margin-right: $spacer0
		.select
			max-width: 580px
	.modalChannelsCarousel
		padding: 0 $spacer2 $spacer4
		+mediaMin640
			padding: 0 $spacer4 $spacer4
		> [class*="sc-"]
			> [class*="sc-"]
				&:first-child
					+ [class*="sc-"]
						margin: 0 5px 0 5px !important
						padding: 0 15px 15px 15px
			[type="prev"], [type="next"]
				+bx
				+font-size(24px)
				width: 40px
				height: 40px
				box-shadow: none
				opacity: 1
				border: $color-text 2px solid
				&:before
					width: auto
					height: auto
					-webkit-transform: translate(-50%,-50%)
					-ms-transform: translate(-50%,-50%)
					transform: translate(-50%,-50%)
					left: 50%
					top: 50%
					border: none
			[type="prev"]
				left: -40px
				&:before
					+claroIcon('\e910')
			[type="next"]
				right: -40px
				&:before
					+claroIcon('\e912')
			.sc-htoDjs
				bottom: -30px
				> div
					> div
						width: 12px
						height: 12px