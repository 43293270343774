@import "../../abstracts/variables"
@import "../../abstracts/mixins"

/*Modal Solicitar*/

.mSolicitar
	width: 480px
	.mSolTitle
		+font-size(18px)
		font-weight: 700
		margin-bottom: $spacer
		text-align: center
	.mSolBundle
		+flex
		+justifyCont(center)
		+font-size(18px)
		font-weight: 700
		margin-bottom: $spacer2
		p
			position: relative
			padding-left: 23px
			i
				+font-size(20px)
				position: absolute
				left: 0
				top: 2px
			+ p
				margin-left: $spacer3
				&:before
					+font-size(20px)
					content: '+'
					position: absolute
					left: -19px
					top: -1px
					display: block
	.mSolDesc
		margin-bottom: $spacer3
	.mSolSubs
		+flex
		+flexDir(column)
		+alignItem(center)
		p
			text-align: center
			font-weight: 500
			width: 100%
			margin-bottom: $spacer2
		[class*="btn"]
			width: 240px
			+ [class*="btn"]
				margin-top: $spacer2
	.mSolForm
		p
			text-align: center
			font-weight: 500
			width: 100%
			margin-bottom: $spacer2
		form
			+flex
			+flexDir(column)
			+justifyCont(space-between)
			margin-bottom: $spacer2
			fieldset
				width: 100%
				+ fieldset
					margin-top: $spacer2
					input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="date"], input[type="tel"]
						height: auto
						padding: $spacer $spacer2
			.mSolFormActions
				+flex
				+flexDir(column)
				+alignItem(center)
				width: 100%
				margin-top: $spacer2
				[class*="btn"]
					width: 200px
					margin-bottom: $spacer2
	
	+mediaMin640
		.mSolForm
			form
				+flexDir(row)
				+flexWrap
				fieldset
					width: 120px
					.select
						min-width: 120px
					+ fieldset
						width: calc(100% - 130px)
						margin-top: 0
				
