// Pagination Mixin
.pagination
    ul
        +flex
        +alignItem(center)
        li
            position: relative
            cursor: pointer
            margin: 0 $spacer/2
            &.pag
                +bx
                +borderRadius(50%)
                width: 32px
                height: 32px
                border: $color-border 1px solid
                span
                    +center(vh)
                    +font-size(14px)
                    display: block
                &.active
                    border: $rojo6 2px solid
                    span
                        font-weight: 700
                        color: $rojo6
            &.pagWord
                margin: 0
                font-weight: 700
                width: 32px
                height: 32px
                +mediaMin640
                    width: auto
                    height: auto
                    margin: 0 $spacer/2
                span
                    display: none
                    +mediaMin640
                        display: block
                i
                    +center(vh)
                    +font-size(24px)
                    display: block
                    +mediaMin640
                        display: none
            &.pagArrow
                margin: 0
                width: 32px
                height: 32px
                i
                    +center(vh)
                    +font-size(24px)
                    display: block
            