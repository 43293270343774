.dropdown
    width: 100%
    // min-width: 300px
    &__control
    &__menu
        background: black
        width: 100px
        
        
        &::before
            content: '.'
            position: absolute
            right: 10px
            top: -10px
            background: $gris1
            color: $gris1
            width: 10px
            height: 10px
            clip-path: polygon(50% 40%, 0% 100%, 100% 100%)
    &__clear-indicator
        padding: 8px 0 8px 8px !important
    &__indicator-separator
        display: none
    &.dropdown-dark
        .dropdown__menu
            
            &::before
                background: $gris7
                color: $gris7
        .dropdown__input
            color: $gris1
.dropdownLabel
    +font-size(16px)
    margin-bottom: $spacer
    display: block
    &.dropdownLabelBold
        font-weight: 600
.dropdownRadios
    .dropdownRadiosOption
        +flex
    .dropdownRadiosSingleValue
        +flex
        margin-left: $spacer
    .dropdown__value-container--has-value
        &:before
            width: 20px
            background: $azul5
            content: "."
            height: calc(100% - 0px)
            border-radius: 25px 0 0 25px
            left: 0px
            top: -1px
            position: absolute
            z-index: 99