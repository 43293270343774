.searchField
    +flex
    +alignItem
    position: relative
    &.icon-search
        &::before
            position: absolute
            margin-left: $spacer
    .fieldClear
        position: absolute
        right: $spacer
        cursor: pointer
        padding-right: 0

        border: 1px solid $gris5
        border-radius: 50%
        width: $spacer
        height: $spacer
        &::before
            +alignSelf
            +flex
            +justifyCont
            padding-right: 0
            padding-top: 4px
    input
        +bx
        padding-left: $spacer3
        padding-right: $spacer3
  
    &:before
        color: $gris5
    &-dark
        .fieldClear
            color: $gris1
            border-color: $gris1
        &:before
            color: $gris3