.textField
    position: relative
    label
        display: block
        // margin-bottom: $middle-spacer
        &.textFieldLabel
            +font-size(16px)
            &.textFieldLabelBold
                font-weight: 600
    input
        // min-width: 300px
        &.input-success
            border: 1px solid $verde9
            color: $verde9 
            background: $verde2
            ~ .status
                color: $verde9    
                background: $verde9
                &::before
                    padding-left: 3px
        &.input-error
            border: 1px solid $rojo5
            color: $rojo5
            background: $rojo1
            ~ .status
                color: $rojo5    
                // background: $p-rojo3
                +font-size(20px)
                &::before
                    padding-left: 5px
        &.input-dark
            &.input-success
                border: 1px solid $verde9
                color: $verde9
                background: $gris5
                // ~ .status
                //     &::before
                //         padding-left: 3px
            &.input-error
                // border: 1px solid $verde5
                color: $rojo5
                background: $gris7
                // ~ .status
                //     &::before
                //         padding-left: 3px
    span
        &.status
            position: absolute
            height: 20px
            right: 10px
            bottom: 12px
            font-size: 1em
            border-radius: 50%
            align-items: center
            display: flex
            width: 20px
   
        