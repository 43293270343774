.paginationMolMain
    +flex
    // width: 100%
    // margin: 0 auto
    .paginationMolFirst
        +flex
        margin-right: $spacer
        +alignSelf
        font-weight: 500
        cursor: pointer

    .paginationMolLast
        +flex
        +alignSelf
        font-weight: 500
        margin-left: $spacer
        cursor: pointer

    .paginationMol
        +flex
        padding: 14px 0
        // padding: 14px 0
        +justifyCont(center)
        width: 100%
        +mediaMin960
            padding: 0 0
            +justifyCont(flex-start)
            width: auto
        li
            list-style: none
        .paginationMolSub
            background: blue
        .paginationMolPage, .paginationMolBreak
            margin-right: $spacer
            border-radius: 50%
            +flex
            +justifyCont(center)
            vertical-align: middle
            +alignItem(center)
            position: relative
            max-width: $spacer3
            max-height: $spacer3                      
            cursor: pointer 
            border: 0
            +mediaMin960
                border: $gris3 1px solid
            a
                padding: 8px 0
                border-radius: 50%
                +mediaMin960
                    padding: 8px 10px 
        .paginationMolBreak
            margin-right: 5px
            &::after
                content: '-'
            +mediaMin960
                &::after
                    content: ''
            ~ .paginationMolBreak  
                &::after
                    content: 'de'
                +mediaMin960
                    &::after
                        content: ''

        .paginationMolActive
            border: 0
            +mediaMin960
                border: $rojo5 2px solid
                color: $rojo5
            .paginationMolActiveLink
                font-weight: 700
                color: $rojo5
                +mediaMin960
                    font-weight: 500
                    color: $gris5

        .paginationMolPrev
            +flex
            +alignSelf
            margin-right: $spacer
            font-weight: 500
            cursor: pointer
            // &::after
            //     font-weight: 400
            //     +flex
            //     +alignSelf
            //     content: 'Página'

            //     +mediaMin960
            //         +claroIcon('e92f')
        .paginationMolPrevCont
            // margin-right: $spacer
            &::after
                font-weight: 400
                +flex
                +alignSelf
                content: 'Página'

                +mediaMin960
                    +claroIcon('e92f')

        .paginationMolNext
            font-weight: 500
            +alignSelf
            cursor: pointer
            // &::after
            //     +mediaMin960
            //         +claroIcon('e930')
        .paginationMolNextCont
            // margin-left: $spacer
            &::after
                +mediaMin960
                    +claroIcon('e930')
        
     
// GoPage
.goPageMol
    display: none !important
    +mediaMin960
        +flex
        +alignItem
    .goPageMolText
        min-width: 100px
        margin-bottom: 0
    .goPageMolSelect
        width: 70px
        min-width: 55px
        margin-bottom: 0
    .goPageMolBtn
        min-width: 85px
        margin-left: $spacer
        margin-bottom: 0
        +mediaMin1102
            min-width: 120px