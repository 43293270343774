//- Colores
$rojo1: #fcf3f3
$rojo5: #f65041
$rojo6: #DA291C
$rojo8: #B52217
$gris1: #F4F4F4
$gris2: #E3D3D3 //checar con Omar por que es muy rojizo este gris y este es del tema oscuro
$gris3: #BBB
$gris4: #979797
$gris5: #6c6c6c
$gris7: #3C3C3C
$gris8: #313131
$gris9: #1f1d1d
$gris10: #726C6C
$azulClaro: #e8f4f6
$azul1: #c7f9fc
$azul5: #0097A9
$amarillo6: #FFC721
$amarillo7: #ffad17 
$amarillo9: #ff8300
$verde2: #DDF9ED
$verde9: #006B4F

$color-text: $gris7
$color-border: $gris3
$color-link: $azul5
$color-bg: $gris1

//- Spaces
$spacer0: 8px
$spacer: 12px
$spacer2: 20px
$spacer3: 32px
$spacer4: 40px
$spacer5: 56px
$spacer6: 72px

//- Font
$fontPrin: 'Roboto', sans-serif
$fontSec: 'Gibson', sans-serif
$remVal: 16px
$fontSize: 16px

//- Breakpoints
$breakpoint-ss: 240px
$breakpoint-s: 320px
$breakpoint-sm: 480px
$breakpoint-m: 640px
$breakpoint-md: 760px
$breakpoint-d: 960px
$breakpoint-dl: 1100px
$breakpoint-lg: 1280px
$breakpoint-lt: 1400px
$breakpoint-xl: 1560px

//- Grid 16
$grid: 16

$grid-16: 100%
$grid-15: 93.75%
$grid-14: 87.5%
$grid-13: 81.25%
$grid-12: 75%
$grid-11: 68.75%
$grid-10: 62.5%
$grid-9: 56.25%
$grid-8: 50%
$grid-7: 43.75%
$grid-6: 37.5%
$grid-5: 31.25%
$grid-4: 25%
$grid-3: 18.75%
$grid-2: 12.5%
$grid-1: 6.25%