//- Variables
@import "../../abstracts/variables"
@import "../../abstracts/mixins"

/*!*C08/C52*/
.c08Wrap
	overflow: hidden
	// .genTabs
	// 	> h2, > h3
	// 		text-align: center
	// 		width: 85%
	// 		margin-left: auto
	// 		margin-right: auto
	// 		margin-bottom: $spacer3
.c08Body
	position: relative
	> h2
		text-align: center
		width: 85%
		margin-left: auto
		margin-right: auto
		margin-bottom: $spacer3
	.c08BodyCompare
		+flex
		+flexDir(column)
		margin-bottom: $spacer3
		min-height: $spacer4
		.h4
			width: 100%
			text-align: center
			margin-bottom: $spacer3
			+flex
			+justifyCont
			+alignItem
			// z-index: 1
		.c08BodyCompareBox
			+flex
			+justifyCont(center)
			min-width: 330px
			width: 100%
			// z-index: 1
			fieldset
				+flex
				+alignItem
				padding-right: $spacer2
				// label
			// a
		+ .c08BodyCards
			margin-top: -10px
	.c08Bar
		margin-bottom: $spacer2
		fieldset
			width: 100%
			label
				font-weight: 700
				display: inline-block
				margin-bottom: $spacer/2
			[class*="btn"]
				width: 100%
				display: table
				max-width: 380px
				margin: 0 auto
			+ fieldset
				margin-top: $spacer2
	.c08SubMenu
		margin: 0 auto
		margin-bottom: $spacer2
		max-width: 330px
		> p
			+font-size(14px)
			padding-bottom: $spacer0
		.c08SubMenuElms
			min-width: 330px
		+ .c08BodyCards2
			margin-top: -10px

	.c08BodyCards, .c08BodyCards2
		width: calc(100% + 80px)
		margin-left: -$spacer2
		margin-right: -$spacer2
		margin-bottom: $spacer3 - 2
		@media only screen and (min-width: 360px)
			width: calc(100% + 40px)

		.cPlanV2, .cPlanV2OfferShow
			min-width: 240px
			max-width: 240px
			+mediaMin320
				min-width: 265px
				max-width: 265px
			+mediaMin480
				min-width: 280px
				max-width: 300px
			+mediaMin640
				min-width: 260px
				max-width: 260px
				margin-left: auto
				margin-right: auto
			+mediaMin760
				min-width: 300px
				max-width: 300px
			// +mediaMin960
			// 	min-width: 260px
			// 	max-width: 260px
			+mediaMin1102
				min-width: 300px
				max-width: 300px

		.slick-slide
			+bx
			padding: #{$spacer - 2} 0 #{$spacer - 2} $spacer2
			+mediaMin640
				padding: #{$spacer - 2} 0 0 0
			// padding: $spacer 0 $spacer 0
			// padding: $spacer 0 $spacer $spacer2
			// @media only screen and (min-width: 360px)
			// &.slick-active 
			// 	// width: calc(100vw - 0px) !important
			// 	width: calc(100vw - 40px) !important
			// 	// width: calc(100vw -  ( (100vw / 100) * 15) ) !important
			// 	transition: width 1.5s
			// 	@media only screen and (min-width: 360px)
			// 		width: calc(100vw -  ( (100vw / 100) * 17) ) !important
			// 	+mediaMin480
			// 		width: calc(100vw -  ( (100vw / 100) * 18) ) !important
			// 	+mediaMin520
			// 		width: calc(100vw -  ( (100vw / 100) * 19)) !important
			// 	+mediaMin640
			// 		transition: unset
			// 		transition-delay: 0
			// 		width:  auto !important
				// +mediaMin960
				// > div
					// +bx
					// padding-left: 20px
					// @media only screen and (min-width: 360px)
					// 	padding-left: 0px
			// &.slick-cloned
			// 	display: none
			// 	// +mediaMin320
			// 	// 	width: calc(100vw - ( (100vw / 100) * 17) ) !important
		.slick-list
			padding: 0 15% 0 0 !important
			+mediaMin640
				padding: 0 !important
				width: 100% !important
		.slick-dots
			//bottom: -25px
			line-height: 0
			position: static
			margin-top: $spacer2
			+mediaMin640
				margin-top: #{$spacer * 2}
			li
				width: 12px
				height: 12px
				margin: 0 $spacer/2
				button
					+bx
					+fx(all, 0.5s)
					+borderRadius(50%)
					position: relative
					width: 12px
					height: 12px
					padding: 0
					background-color: $gris3
					&:before
						display: none
				&.slick-active
					button
						background-color: $gris7

	// .c08BodyCards
	// 	.slick-slide
	// 		&.slick-active 
	// 			// width: calc(100vw - 0px) !important
	// 			width: calc(100vw - 40px) !important
	// 			// width: calc(100vw -  ( (100vw / 100) * 15) ) !important
	// 			transition: width 1.5s
	// 			@media only screen and (min-width: 360px)
	// 				width: calc(100vw -  ( (100vw / 100) * 17) ) !important
	// 			+mediaMin480
	// 				width: calc(100vw -  ( (100vw / 100) * 18) ) !important
	// 			+mediaMin520
	// 				width: calc(100vw -  ( (100vw / 100) * 19)) !important
	// 			+mediaMin640
	// 				transition: unset
	// 				transition-delay: 0
	// 				width:  auto !important
	// .c08BodyCards2
	// 	.slick-slide
	// 		.cPlanV2Wrap 
	// 			+flex
	// 			+alignItem(center)
	// 			+flexDir(column)
	// 			padding-top: 10px
	// .c08BodyPlans
	// 	+flex
	// 	+flexWrap(wrap)
	// 	margin-bottom: -30px
	// 	margin-right: -$spacer
	// 	margin-left: -$spacer
	// 	.c08BodyPlanItem
	// 		+flex
	// 		+justifyCont
	// 		width: calc(100% - #{$spacer2})
	// 		margin-bottom: 30px
	// 		margin-right: $spacer
	// 		margin-left: $spacer
.c08Button
	margin-top: $spacer
	// margin-top: $spacer4
	[class*="btn"]
		width: 100%
		max-width: 380px
		margin: 0 auto
		display: table
.c08ButtonPagination
	margin-top: $spacer
	+flex
	+justifyCont
			
+mediaMin640
	.c08Wrap
		// .genTabs
		// 	> h2, > h3
		// 		width: 100%
		// 		margin-bottom: $spacer3
	.c08Body
		// .c08BodyCompare
			
		// 	.h4
			// .c08BodyCompareBox
		> h2
			width: 100%
		
		.c08BodyCards, .c08BodyCards2
			+flex
			+flexWrap
			width: calc(100% + 20px)
			margin-left: -$spacer
			margin-right: -$spacer
			.c08BodyCardWrap
				+bx
				+flex
				padding: 0 ($spacer - 2)
				.cardPlan, .cPlanV2
					+fx(all, 0.5s)
					&:hover
						+shadow(0px, 4px, 8px, 0px, rgba(50,50,50,0.3))
						+transform(translate(0px, -10px))
		.c08BodyCards
			.c08BodyCardWrap
				padding: $spacer - 2
		// .c08BodyCards2
			
		.c08Bar
			+flex
			+justifyCont(center)
			margin-bottom: $spacer3
			fieldset
				+flex
				+alignItem(center)
				width: auto
				.select
					max-width: 240px
				&.c54Actions
					max-width: 120px
					[class*="btn"]
						margin: 0
				label
					margin-right: $spacer
					margin-bottom: 0
				+ fieldset
					margin-top: 0
					margin-left: $spacer2
			+ .c08BodyCards2
				margin-top: -(#{$spacer - 2})
		// .c08BodyPlans
		// 	.c08BodyPlanItem
		// 		width:  calc(50% - #{$spacer2})
		.c08SubMenu
			margin-bottom: $spacer3
	.c08Button
		margin-top: $spacer4
	.c08ButtonPagination
		+justifyCont(flex-end)

+mediaMin760
	.c08Body
		.c08BodyCompare
			+flexDir(row)
			.h4
				width: 50%
				margin-bottom: 0 
				+justifyCont(flex-start)
			.c08BodyCompareBox
				width: 50%
	
			
				
+mediaMin960
	.c08Body
		.c08Bar
			fieldset
				label
					white-space: nowrap
		// .c08BodyPlans
		// 	.c08BodyPlanItem
		// 		width:  calc(33.3333% - #{$spacer2})
	
+mediaMin1102
	.c08Body
		// .c08BodyCards, .c08BodyCards2
		// 	margin-left: 0
		// 	margin-right: 0
		.c08BodyCompare
			.h4
				+justifyCont
				width: 100%
			.c08BodyCompareBox
				position: absolute
				right: 0
				top: 0
				width: auto
				// max-width: 330px


///////////////////////// TEMPORAL

.c08BodyCards
	&.tempFix
		.cPlanV2
			margin: 0 auto
			min-width: initial
		.cPlanV2Offer
			margin: $spacer2 auto
			min-width: initial
		.c08BodyCardWrap
			+mediaMin640
				padding: $spacer $spacer2
			.cPlanV2Wrap
				+bx
				width: 100%
				padding: 0 $spacer2
				.cPlanV2Flag
					display: none
		
.sliderTabsViewplanesDestacados
	display: block !important
	-webkit-animation: fade-in 0.5s ease
	-moz-animation: fade-in 0.5s ease
	.c08Body
		margin-top: $spacer3
		+mediaMin640
			margin-top: $spacer4