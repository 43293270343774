@import "../../abstracts/variables"
@import "../../abstracts/mixins"

/*Modal Tooltip*/
.modalTooltip
	+borderRadius($spacer)
	width: 520px
	padding: $spacer4 $spacer3 $spacer3 !important
	.modalTooltipWrap
		h1, h2, h3, h4, h5, h6
			+ p
				margin-top: $spacer2
		p
			+mediaMin640
				+font-size(14px)
			+ p, + h1, + h2, + h3 , + h4, + h5, + h6
				margin-top: $spacer2