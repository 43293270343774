@import "../../abstracts/variables"
@import "../../abstracts/mixins"

.mLogin
    .mLoginHeader
        padding-bottom: $spacer2
        border-bottom: 2px solid $gris1
        p
            +font-size(20px)
            font-weight: 700
    form
        margin-top: $spacer2
        p
            font-weight: 700
            color: $gris5
        fieldset
            // margin-top: $spacer2
        .mLoginElm
            margin-top: $spacer2
            p
                margin-bottom: $spacer
        .mLoginLinks
            margin-top: $spacer
            a
                text-decoration: underline
                +font-size(14px)
            p
                margin-top: $spacer3
                a
                    +font-size(16px)

        .mLoginAct
            +flex
            +justifyCont(space-between)
            +flexDir(column)
            margin-top: -$spacer2
            padding-top: $spacer2
            +mediaMin960
                +flexDir(row)
                // padding-top: 0
                margin-top: 0
            a
                margin-top: $spacer2
                +mediaMin960
                    margin-top: 0