//- Abstracts
// @import "../abstracts/mixins"
// @import "../abstracts/variables"

//stepsPoint
.stepsPoint
    +flex
    +flexDir(column)
    width: 100%
    +mediaMin960
        +flexDir(row)
    .stepsPointItem
        +flex
        +flexDir(row)
        position: relative
        width: 100%
        +mediaMin960
            +flexDir(column)
        &::before
            content: ''
            position: absolute
            left: 10px
            top: 30px
            background: $gris3
            height: $spacer3
            width: 1px
            +mediaMin960
                right: 0
                left: auto
                top: 20px
                height: 3px
                width: calc( 50% - 20px)
        &::after
            content: ''
            left: 10px
            position: absolute
            background: $gris3
            height: $spacer3
            width: 1px
            top:-10px
            +mediaMin960
                left: 0
                top: 20px
                height: 3px
                width: calc( 50% - 20px)
        &:first-child
            width: 100%
            +mediaMin960
                width: 50%
            .stepsPointNumber
                +justifyCont(flex-start)
                padding-left: 0
            .stepsPointTitle
                +justifyCont(flex-start)
                p
                    font-weight: 600
                    text-align: left
            &::before
                width: 0
                +mediaMin960
                    width: calc(100% - 35px )
            &::after
                display: none
        &:last-child
            width: 100%
            +mediaMin960
                width: 50%
            .stepsPointNumber
                +justifyCont(flex-end)
                padding-right: $spacer0
                +mediaMin960
                    padding-right: 0
            .stepsPointTitle
                +justifyCont(flex-end)
                p
                    text-align: left
                    +mediaMin960
                        text-align: right
            &::before
                display: none
            &::after
                width: 0
                +mediaMin960
                    width: calc(100% - 35px)
        .stepsPointNumber
            +flex
            +alignItem
            +justifyCont
            padding: 10px $spacer0 10px 0
            +mediaMin960
                padding: 10px $spacer2
            &::after
                +flex
                +alignItem
                +justifyCont
                width: 20px
                height: 20px
                border-radius: 50%
                border: 1px solid $gris5
                color: #fff
                // padding: 7px 10px
                content: attr(data-number)
                z-index: 999
        .stepsPointTitle
            +flex
            +justifyCont
            +alignItem
            +mediaMin960
                +alignItem(flex-start)
            p
                // max-width: 80px
                text-align: center
                +mediaMin320
                    // max-width: 120px
                +mediaMin480
                    // max-width: 140px
                +mediaMin960
                    max-width: 140px
      
                
        &.stepsPointItemPending
            .stepsPointNumber
                &::after
                    background: #fff
                    color: $gris5
            .stepsPointTitle
                p
                    font-weight: 300
        &.stepsPointItemActive
            .stepsPointNumber
                &::after
                    background: #fff
                    border: 2px solid $verde9
                    // border: 2px solid  $p-rojo5
                    color: $verde9
                    // color: $p-rojo5
                    font-weight: 600
            .stepsPointTitle
                p
                    color: $verde9
                    // color: $p-rojo5
                    font-weight: 600
        &.stepsPointItemFinish
            .stepsPointNumber
                &::after
                    background: #fff
                    color: $gris8
                    font-weight: 700
                    border: 2px solid $gris8
            .stepsPointTitle
                p
                    font-weight: 600