
.noUi-connects
    .noUi-connect
        background: $rojo5 !important
.noUi-horizontal 
    height: $spacer !important
    .noUi-handle
        width: 15px !important
        height: 15px !important
        right: -5px !important
        top: -5px !important
        border-radius: 50%
        border: 3px solid $rojo5

.rangeSliderCom
    .rangeSliderComSlider
        margin-bottom: $spacer2
        .noUi-base
            z-index: 0
    .rangeSliderComMbInd
        +font-size(14px)
        line-height: 16px
        padding-bottom: $spacer0
    .rangeSliderComMbVals
        +flex
        +justifyCont(space-between)
        margin-top: -15px
        p, span
            +font-size(14px)
    .rangeSliderComInputs

        +flex
     

        .textDescribe
            +bx
            +flex
            +alignSelf
            padding-right: $spacer
            padding-bottom: $spacer
            // vertical-align: middle
            // +justifyCont
            // height: 100%
            +mediaMin960
                padding-bottom: 0
                // +alignSelf(start)
                // padding-right: 0
            &.rangeA
                padding-left: $spacer
        .rangeSlider-InputSelect, .rangeSlider-InputNumber 
            padding-bottom: $spacer
            +mediaMin960
                padding-bottom: 0
            input
                min-width: 80px
                // +mediaMin960
                //     min-width: 70px
                // +mediaMin1280
                //     min-width: 80px