//-Funciones
@function strip-unit($num)
	@return $num / ($num * 0 + 1)

@function calculateRem($size)
	$remSize: $size / $remVal
	@return $remSize * 1rem

@function unicode($str)
	@return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"")

//- Mixines
=font-size($size, $imp...)
	font-size: $size $imp
	font-size: calculateRem($size) $imp

=remsXpx($property, $values... )
	$max: length($values)
	$pxValues: ''
	$remValues: ''

	@for $i from 1 through $max
		$value: strip-unit(nth($values, $i))
		$pxValues: #{$pxValues + $value*strip-unit($remVal)}px
		@if $i < $max
			$pxValues: #{$pxValues + " "}

	@for $i from 1 through $max
		$value: strip-unit(nth($values, $i))
		$remValues: #{$remValues + $value}rem

		@if $i < $max
			$remValues: #{$remValues + " "}

	#{$property}: $pxValues
	#{$property}: $remValues

=pxXrems($property, $values...)
	$max: length($values)
	$pxValues: ''
	$remValues: ''

	@for $i from 1 through $max
		$value: strip-unit(nth($values, $i))
		$pxValues: #{$pxValues + $value}px

		@if $i < $max
			$pxValues: #{$pxValues + " "}

	@for $i from 1 through $max
		$value: strip-unit(nth($values, $i))
		$remValues: #{$remValues + $value/strip-unit($remVal)}rem

		@if $i < $max
			$remValues: #{$remValues + " "}
	#{$property}: $pxValues
	#{$property}: $remValues

=svgBg($name)
	background: url('img/#{$name}.png') transparent 0 0 no-repeat
	background: none, url('vector/#{$name}.svg') 0 0 no-repeat

=out
	border: 1px dotted black

=bx($value: border-box)
	-moz-box-sizing: $value
	-webkit-box-sizing: $value
	box-sizing: $value

=padd
	padding: $min-spacer+3 $spacer

=bgSvg($bg-url, $color)
	background-color: $color
	mask: url(vector/$bg-url) no-repeat
	-webkit-mask: url(vector/$bg-url) no-repeat


=linked
	color: $color-link
	text-decoration: underline

=appearance
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none

=userSelect($value: none)
	-webkit-user-select: $value
	-khtml-user-select: $value
	-moz-user-select: $value
	-o-user-select: $value
	user-select: $value

=inputNumber
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button
		+appearance
		margin: 0

=aReset
	color: inherit
	text-decoration: none

=center($value: v)
	position: absolute
	@if $value == v
		-webkit-transform: translateX(-50%)
		-moz-transform: translateX(-50%)
		-ms-transform: translateX(-50%)
		transform: translateX(-50%)
		left: 50%
	@else if $value == h
		-webkit-transform: translateY(-50%)
		-moz-transform: translateY(-50%)
		-ms-transform: translateY(-50%)
		transform: translateY(-50%)
		top: 50%
	@else if $value == vh
		-webkit-transform: translate(-50%, -50%)
		-moz-transform: translate(-50%, -50%)
		-ms-transform: translate(-50%, -50%)
		transform: translate(-50%, -50%)
		top: 50%
		left: 50%
	@else if $value == clear
		position: relative
		-webkit-transform: none
		-moz-transform: none
		-ms-transform: none
		transform: none
		top: initial
		left: initial

=translate($X,$Y)
	-webkit-transform: translate($X, $Y)
	-moz-transform: translate($X, $Y)
	-ms-transform: translate($X, $Y)
	transform: translate($X, $Y)

=translateNoneImportant()
	-webkit-transform: none !important
	-moz-transform: none !important
	-ms-transform: none !important
	transform: none !important

=scale($value1...)
	-webkit-transform: scale($value1)
	-moz-transform: scale($value1)
	-ms-transform: scale($value1)
	transform: scale($value1)

=transform($values...)
	-webkit-transform: $values
	-moz-transform: $values
	-ms-transform: $values
	transform: $values

=transOrigin($values...)
	-webkit-transform-origin: $values
	-moz-transform-origin: $values
	-ms-transform-origin: $values
	transform-origin: $values

=filter($values...)
	-webkit-filter: $values
	filter: $values

=gradient($deg, $color_A, $color_B)
	background: $color_A
	background: -moz-linear-gradient($deg, $color_A 0%, $color_B 100%)
	background: -webkit-gradient($deg, color-stop(0%, $color_A), color-stop(100%, $color_B))
	background: -webkit-linear-gradient($deg, $color_A 0%, $color_B 100%)
	background: -o-linear-gradient($deg, $color_A 0%, $color_B 100%)
	background: -ms-linear-gradient($deg, $color_A 0%, $color_B 100%)
	background: linear-gradient($deg, $color_A 0%, $color_B 100%)
	@if $deg >=0 and $deg <=90
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color_A}', endColorstr='#{$color_B}', GradientType=0 )
	@if $deg >=91 and $deg <=180
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color_A}', endColorstr='#{$color_B}', GradientType=1 )
	@if $deg >=181 and $deg <=270
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color_B}', endColorstr='#{$color_A}', GradientType=0 )
	@if $deg >=271 and $deg <=360
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color_B}', endColorstr='#{$color_A}', GradientType=1 )

=fx($elem, $time: 1s, $effect: ease)
	-webkit-transition: $elem $time $effect
	-moz-transition: $elem $time $effect
	-ms-transition: $elem $time $effect
	transition: $elem $time $effect

=fxr
	-webkit-transition: inherit
	-moz-transition: inherit
	-o-transition: inherit
	-ms-transition: inherit
	transition: inherit

=scrollMobile
	overflow-x: hidden
	overflow-y: scroll
	-webkit-overflow-scrolling: touch

=shadow($s1: 0, $s2: 4px, $s3: 8px, $s4: 0px, $alpha: rgba(0,0,0,0.15))
	-webkit-box-shadow: $s1 $s2 $s3 $s4 $alpha
	-moz-box-shadow: $s1 $s2 $s3 $s4 $alpha
	-o-box-shadow: $s1 $s2 $s3 $s4 $alpha
	-ms-box-shadow: $s1 $s2 $s4 $s3 $alpha
	box-shadow: $s1 $s2 $s3 $s4 $alpha

=claroIcon($value, $imp: '')
	content: unicode($value) #{$imp}
	font-family: 'claroicons'

=calc($propiedad, $exprecion)
	#{$propiedad}: -webkit-calc(#{$exprecion})
	#{$propiedad}: -moz-calc(#{$exprecion})
	#{$propiedad}: calc(#{$exprecion})

//- Bloque Experimental Flex-Box
=flex($imp: '')
	display: -webkit-box #{$imp}
	display: -moz-box #{$imp}
	display: -ms-flexbox #{$imp}
	webkit-display: -webkit-flex #{$imp}
	display: -webkit-flex #{$imp}
	display: flex #{$imp}

=flexDir($dir: column)
	flex-direction: $dir
	-webkit-box-direction: $dir
	-webkit-flex-direction: $dir
	-ms-flex-direction: $dir

=flexWrap($dir: wrap)
	flex-wrap: $dir
	-webkit-box-wrap: $dir
	-webkit-flex-wrap: $dir
	-ms-flex-wrap: $dir

=alignSelf($dir: center)
	align-self: $dir
	-webkit-align-self: $dir
	-ms-flex-self: $dir

=alignItem($dir: center)
	align-items: $dir
	-webkit-align-items: $dir
	-ms-flex-align: $dir

=alignCont($dir: flex-start)
	align-content: $dir
	-webkit-align-content: $dir

=justifyCont($dir: center)
	justify-content: $dir
	-webkit-justify-content: $dir
	-ms-flex-pack: $dir
	@if $dir == "flex-end"
		-ms-flex-pack: end
	@if $dir == "flex-start"
		-ms-flex-pack: start
	@if $dir == "space-around"
		-ms-flex-pack: justify
	@if $dir == "space-between"
		-ms-flex-pack: justify

=order($dir)
	order: $dir
	-webkit-order: $dir

=placeholder
	&::-webkit-input-placeholder
		@content
	&:-moz-placeholder
		@content
	&::-moz-placeholder
		@content
	&:-ms-input-placeholder
		@content

=flexReset
	display: block !important
	webkit-display: block !important

=headerColores($color: $rojo, $width: $g1)
	background-color: $color
	width: $width
	height: 5px

=borderRadius($size)
	-webkit-border-radius: $size
	-moz-border-radius: $size
	border-radius: $size

=borderRadiusFull($topL, $topR, $bottomR, $bottomL )
	-webkit-border-radius: $topL $topR $bottomR $bottomL
	-moz-border-radius: $topL $topR $bottomR $bottomL
	border-radius: $topL $topR $bottomR $bottomL

=transformRotate($deg: 0deg)
	-ms-transform: rotate($deg)
	-webkit-transform: rotate($deg)
	-o-transform: rotate($deg)
	-ms-transform: rotate($deg)
	transform: rotate($deg)

=cardFormat
	+shadow
	+borderRadius(12px)
	// -webkit-box-shadow: 0px 4px 8px 0px rgba(50,50,50,0.15)
	// -moz-box-shadow: 0px 4px 8px 0px rgba(50,50,50,0.15)
	// -o-box-shadow: 0px 4px 8px 0px rgba(50,50,50,0.15)
	// -ms-box-shadow: 0px 4px 8px 0px rgba(50,50,50,0.15)
	// box-shadow: 0px 4px 8px 0px rgba(50,50,50,0.15)
	background-color: #fff
	overflow: hidden

//- Mediaqueries
//- -xl
=mediaMin1500
	@media only screen and (min-width: 1500px)
		@content

//- -lt
=mediaMin1400
	@media only screen and (min-width: 1400px)
		@content

//- -lg
=mediaMin1280
	@media only screen and (min-width: 1280px)
		@content
		
=mediaMin1200
	@media only screen and (min-width: 1200px)
		@content
//- -ld
=mediaMin1102
	@media only screen and (min-width: 1102px)
		@content

//- -d Tablet
=mediaMin960
	@media only screen and (min-width: 960px)
		@content

//- -md
=mediaMin760
	@media only screen and (min-width: 760px)
		@content

//- -m Smartphone
=mediaMin640
	@media only screen and (min-width: 640px)
		@content

//- 
=mediaMin520
	@media only screen and (min-width: 520px)
		@content

//- -sm
=mediaMin480
	@media only screen and (min-width: 480px)
		@content

//- -s
=mediaMin320
	@media only screen and (min-width: 320px)
		@content

//- -ss
=mediaMin240
	@media only screen and (min-width: 240px)
		@content

=aspectRadio1X1
	&:before
		content: ""
		width: 1px
		margin-left: 1px
		float: left
		height: 0
		padding-top: 400px / 400px * 100%
	&:after
		content: ""
		display: table
		clear: both

=aspectRadio4X3
	&:before
		content: ""
		width: 1px
		margin-left: 1px
		float: left
		height: 0
		padding-top: 480px / 640px * 100%
	&:after
		content: ""
		display: table
		clear: both

=aspectRadio16X9
	&:before
		content: ""
		width: 1px
		margin-left: 1px
		float: left
		height: 0
		padding-top: 1080px / 1920px * 100%
	&:after
		content: ""
		display: table
		clear: both